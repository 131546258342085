import { ContentType, HttpClient, RequestParams } from '../http-client';

export class Jobs<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  postJobsV2 = (data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  patchJobsV2 = (id: string, data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJob = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApplicants = (id: string, query: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}/applicants`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query,
      ...params,
    });

  getFinals = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}/final`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApplicantsData = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}/data`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApplicantHistory = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}/history`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApplicantsComplete = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}/complete`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  shortlistApplicant = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/shortlist/${id}`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getShortlisted = (id: string, query: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}/shortlisted`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query,
      ...params,
    });

  patchApplicant = (id: string, data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}`,
      method: 'PATCH',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  postApplicantHistory = (id: string, data: any, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}/history`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobs = (
    query: {
      page?: number;
      rank?: string;
      status?: string;
      vessel_type?: string;
      vessel_flag?: string;
      vessel_name?: string;
      // created_by?:string
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/jobs`,
      method: 'GET',
      secure: true,
      ...(query && { query }),
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobsFilter = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/filter`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getApplicantDocument = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}/documents`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  copyJob = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}/copy`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  closeJob = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}/closed`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  activateCbt = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/activate-cbt/${id}`,
      method: 'PATCH',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getFilterInJob = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/${id}/filter`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobCbt = (
    query: {
      rank?: string;
      vessel_type?: string;
      special_skill?: string;
      dp?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/jobs/cbt`,
      method: 'GET',
      secure: true,
      ...(query && { query }),
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobMinimumCertificates = (
    query: {
      rank?: string;
      dp_certificate?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/jobs/minimum-certifictes`,
      method: 'GET',
      secure: true,
      ...(query && { query }),
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobPositions = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/positions`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobApplicantCv = (id: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/applicants/${id}/cv`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getJobSpecialSkill = (query: { rank: string; }, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/jobs/special-skill`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      query,
      ...params,
    });
}
