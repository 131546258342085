import { ContentType, HttpClient, RequestParams } from '../http-client';

export class Company<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;
  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getCompanyV2 = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyUser = (params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/company/user`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getCompanyPublic = (id?: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/public/company/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getPublicCompanyJobs = (id?: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/public/jobs/company/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  getPublicJob = (id?: string, params: RequestParams = {}) =>
    this.http.request<any>({
      path: `/api/v2/public/jobs/${id}`,
      method: 'GET',
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });

  getPublicJobs = (
    query: {
      page?: number;
      rank?: string;
      company?: string;
    },
    params: RequestParams = {}
  ) =>
    this.http.request<any>({
      path: `/api/v2/public/jobs`,
      method: 'GET',
      ...(query && { query }),
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
